.cus_button{
    font-size: 0.875rem;
    position: relative;
    @media (min-width:992px) {
        font-size: 1rem;
    }

    &:hover::after{
        content: '';
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 100%;
        background-color: var(--clr-n-w);
        border-radius: 50rem;
        opacity: 0.5;
    }
}


.btn-gadient {
    background-image: var(--l-pri-g)!important;
}