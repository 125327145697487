@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@300;400;700&display=swap');

:root{
    --clr-pr-db : hsl(233, 26%, 24%);
    --clr-pr-lg : hsl(136, 65%, 51%);
    --clr-pr-bc : hsl(192, 70%, 51%);
    --clr-n-gb : hsl(233, 8%, 62%);
    --clr-n-lgb : hsl(220, 16%, 96%);
    --clr-n-vlg : hsl(0, 0%, 98%);
    --clr-n-w : hsl(0, 0%, 100%);
    --l-pri-g : linear-gradient(to right, var(--clr-pr-bc), var(--clr-pr-lg));
    --modal-bg: linear-gradient(to bottom, hsl(233, 26%, 24%), hsl(233, 26%, 24%, 0));
}

*{
    font-family: 'Public Sans', sans-serif;
}

// body font size 18px

// body{
//     background-color: aqua !important;
// }

h1, h2, h3, h4, h5{
    color: var(--clr-pr-db);
}

p{
    font-size: 0.875rem !important;
    line-height: 150%;
    color: var(--clr-n-gb);
    @media (min-width) {
        font-size: 1.125rem;
    }
}

.fs-sm{
    font-size: 0.85rem !important;
}

